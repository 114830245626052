export default [
    {
        titulo: "Projeto Arquitetônico",
        descricao: "Você tem um sonho e não sabe por onde começar? Comece por um projeto que tenha a sua identidade. Da residência aos projetos comerciais e corporativos, aqui pensamos em um projeto funcional e esteticamente agradável sob medida para você. Prezamos por um projeto executivo bem detalhado, com os quantitativos a serem utilizados na obra (tabela de esquadrias, revestimentos, pontos hidráulicos, luminárias, tomadas etc). Com nosso detalhamento de excelência será muito mais fácil prever os custos e o cronograma da sua obra.",
        imageBackgroundClass: "arquitetonico_background_image",
        toggled: false,
    },
    {
        titulo: "Projeto de Interiores",
        descricao: "Os projetos de interiores são elaborados sempre com respeito ao desejo, identidade e necessidade do cliente. Desejamos transformar sua casa em um lar com qualidade de vida para você e sua família.",
        subDescription: "Se você tem um estabelecimento comercial e precisa de um projeto, aqui seu negócio é prioridade! Desenvolvemos projetos que valorizam seus produtos, colaboradores e a marca da sua empresa.",
        subSubDescription: "Assim como o projeto de arquitetura, prezamos por um projeto executivo de excelência, onde você terá todo o detalhamento de marcenaria, marmoraria entre outros. ",
        imageBackgroundClass: "interiores_background_image",
        toggled: false,
    },
    {
        titulo: "Projeto Legal",
        descricao: "O projeto legal é o documento que elaboramos para submeter à aprovação da Prefeitura do Município. Trabalhamos com Aprovação de Projetos, Regularizações de Imóveis, Desmembramento de lotes, Projetos de Prevenção e Combate a Incêndios. Se você tem dúvidas nesse assunto, entre em contato conosco.",
        imageBackgroundClass: "legal_background_image",
        toggled: false,
    },
    {
        titulo: "Consultoria",
        descricao: "A consultoria de interiores é um serviço para você que quer repaginar seu ambiente de forma rápida e descomplicada.",
        subDescription: "Após conhecer um pouco melhor sobre seus gostos e necessidades (através do briefing) apresentaremos uma planta de layout e todas as vistas do seu ambiente: sugerindo cores, mobiliário e soluções criativas para seu espaço.",
        imageBackgroundClass: "consultoria_background_image",
        toggled: false,
    },
    {
        titulo: "Modelagem 3D",
        descricao: "O serviço de modelagem 3D serve para quem está buscando algo mais pontual, como um projeto de mobiliário(ex: móvel planejado, closet, gabinete etc).",
        subDescription: "Falaremos sobre suas necessidade e faremos um design exclusivo para você. Esse serviço também serve para profissionais de arquitetura que desejam terceirizar o serviço de modelagem de projetos.",
        subSubDescription: "Trabalhamos com os softwares Sketch Up e Revit.",
        imageBackgroundClass: "modelagem_background_image",
        toggled: false,
    },
    {
        titulo: "Acompanhamento de Obra",
        descricao: "Sem tempo para cuidar da sua obra?",
        subDescription: "Nao precisa se preocupar!  Nós cuidamos de todos os detalhes para você!",
        subSubDescription: "O Acompanhamento de obra inclui todo o processo de compra de material até a supervisão do serviço final.",
        subSubSubDescription: "Com o projeto executivo em mãos, faremos um planilha orçamentária com 3 fornecedores dos itens apontados em projeto. Seremos responsáveis pela contratação dos serviços e compras de materiais, de maneira que, sua obra tenha um andamento fluido e sem imprevistos. A execução dos serviços é acompanhada pela nossa equipe, garantindo assim, um melhor resultado final.",
        imageBackgroundClass: "acompanhamento_background_image",
        toggled: false,
    },
];
